import React, { Component } from "react";
import StarFeedbackRecorder from "./star-feedback-recorder";
import NpsFeedbackRecorder from "./nps-feedback-recorder";
import YesNoFeedbackRecorder from "./yes-no-feedback-recorder";
import OnScreenLoader from "./on-screen-loader";
import ThankYou from "./thank-you";
import ThumbsUpDownFeedbackRecorder from "./thumbs-up-down-feedback-recorder";
import { submitFeedback } from "../http-calls";

class FeedbackRecorder extends Component {
  state = {
    recorderType: null,
    isLoading: false
  };

  componentDidMount = () => {};

  _submitFeedback = async feedback => {
    try {
      const formattedFeedback = {};
      formattedFeedback[feedback.type] = feedback.value;
      this.setState({ isLoading: true });
      const response = await submitFeedback(
        this.props.feedbackDetails.token,
        formattedFeedback
      );
      this.setState({ isLoading: false });
      this.props.onRecorded(response);
    } catch (err) {
      this.props.showNoAccessPage();
    }
  };

  _generateCurrentFeedbackMode = () => {
    const { feedbackDetails } = this.props;
    switch (feedbackDetails.funnelType) {
      case "nps": {
        return <NpsFeedbackRecorder onSubmit={this._submitFeedback} />;
      }
      case "yesno": {
        return <YesNoFeedbackRecorder onSubmit={this._submitFeedback} />;
      }
      case "thumbsupdown": {
        return <ThumbsUpDownFeedbackRecorder onSubmit={this._submitFeedback} />;
      }
      case "star": {
        return <StarFeedbackRecorder onSubmit={this._submitFeedback} />;
      }
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ? <OnScreenLoader /> : this._generateCurrentFeedbackMode()}
      </React.Fragment>
    );
  }
}

export default FeedbackRecorder;
