import React, { Component } from "react";
import { feedbackReviewSites } from "../config";
import { submitNegativeReview } from "../http-calls";
import { ToastsStore } from "react-toasts";

class NegativeFeedback extends Component {


  constructor(props) {
    super(props);
    this.state = {
      reviewText: "",
    reviewRecorded: false,
      reviewSites: this._convertFeedbackSitesToSingleArray()
    }
  }

  _convertFeedbackSitesToSingleArray = () => {
    const feedbackReviewSitesArray = [];
    for (let categoryName in feedbackReviewSites ) {
      const siteCount = feedbackReviewSites[categoryName].length;
      for (let siteIndex = 0; siteIndex < siteCount; siteIndex++) {
        feedbackReviewSitesArray.push(feedbackReviewSites[categoryName][siteIndex]);
      }
    }
    return feedbackReviewSitesArray;
  }

  componentDidMount() {
  }

  _getLogo = site => {
    const { reviewSites } = this.state;

    const filteredSite = reviewSites.find(
      s => s.indexName === site.name
    );
    if (filteredSite) {
      return filteredSite.logoUrl;
    } else {
      switch(site.name) {
        case 'gmbUrl': {
          return "https://sites.google.com/a/evoapa.com/evoapa/_/rsrc/1478794464468/samarbetspartner/google.png";
        }
        case 'fbUrl': {
          return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Facebook.svg/1280px-Facebook.svg.png";
        }
        case 'yelpUrl': {
          return "https://www.trzcacak.rs/file/max/2/21631_yelp-logo-png.jpg";
        }
        default: {
          return site.logoUrl;
        }
      }
    }
  };

  _onUpdate = reviewText => {
    this.setState({ reviewText });
  };

  _submitReview = () => {
    submitNegativeReview(
      this.props.feedbackDetails.token,
      this.state.reviewText
    );
    this.setState({ reviewRecorded: true }, () => {
      ToastsStore.success('Thank you for your feedback', 5000);
    });
  };

   _rawHtml = (htmlreceived) => {
    var rawMarkup = htmlreceived
    console.log("raw markup received:", rawMarkup)
    return { __html: rawMarkup }
  }

  render() {
    const { reviewText, reviewRecorded } = this.state;
    const { feedbackDetails, feedbackResponse } = this.props;
    return (
      <React.Fragment>
        <div className="app-body"  style={{ marginTop: "20px" }}>
          <main className="main">
            <div className="container">
              <div className="row"></div>

              <div className="row">
                <div className="col-12">
                  <div className="card mt-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div style={{ marginBottom: 30 }}>
                            {feedbackResponse &&
                            feedbackResponse.thankYouPageInfo.html &&
                            feedbackResponse.thankYouPageInfo.html.length ? (
                              <div
                                className="text-center"
                                style={{ marginBottom: 100 }}
                              >
                                {/* <p>{feedbackResponse.thankYouPageInfo.html}</p> */}
                              <p><span dangerouslySetInnerHTML={this._rawHtml(feedbackResponse.thankYouPageInfo.html)}/>
                              </p>
                              </div>
                            ) : null}
                            {feedbackResponse &&
                            feedbackResponse.thankYouPageInfo.imgUrl &&
                            feedbackResponse.thankYouPageInfo.imgUrl.length &&
                            feedbackResponse.thankYouPageInfo.imgUrl !==
                              "NA" ? (
                              <div
                                className="text-center"
                                style={{ marginBottom: 25 }}
                              >
                                <img
                                  style={{ width: "80%" }}
                                  src={feedbackResponse.thankYouPageInfo.imgUrl}
                                />
                              </div>
                            ) : null}
                            {feedbackResponse &&
                            feedbackResponse.thankYouPageInfo.videoUrl &&
                            feedbackResponse.thankYouPageInfo.videoUrl.length &&
                            feedbackResponse.thankYouPageInfo.videoUrl !==
                              "NA" ? (
                              <div
                                className="text-center"
                                style={{ marginBottom: 25 }}
                              >
                                <video width="80%" height="240" controls>
                                  <source
                                    src={feedbackResponse.thankYouPageInfo.videoUrl.replace(
                                      "blob:",
                                      ""
                                    )}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            ) : null}
                          </div>
                          {!reviewRecorded && !feedbackResponse.review ? (
                            <div
                              className="text-center"
                              style={{ marginBottom: 50 }}
                            >
                              <h3 className="mb-4"> How can we improve?</h3>
                              <div className="form-group mt-4 feedback-textarea">
                                <textarea
                                  className="form-control"
                                  id=""
                                  rows="5"
                                  value={reviewText}
                                  placeholder="Comments (please be as specific as possible)"
                                  onChange={e => this._onUpdate(e.target.value)}
                                ></textarea>
                              </div>
                              <button
                                type="button"
                                className="btn theme-btn btn-lg mt-2 mb-4"
                                style={
                                  !reviewText.length
                                    ? {
                                        background: "grey",
                                        borderColor: "grey"
                                      }
                                    : null
                                }
                                disabled={reviewText.length ? null : true}
                                onClick={() => this._submitReview()}
                              >
                                Submit
                              </button>
                            </div>
                          ) : null}

                          <hr />
                          {(feedbackResponse.isPositive === true || (feedbackResponse.isPositive === false && feedbackResponse.thankYouPageInfo.reviewGating === false) &&
                          ((feedbackResponse &&
                            feedbackResponse.thankYouPageInfo &&
                            feedbackResponse.thankYouPageInfo
                              .receiveFeedbackSites &&
                            feedbackResponse.thankYouPageInfo
                              .receiveFeedbackSites.length) ||
                          (feedbackResponse.thankYouPageInfo.gmbUrl ||
                            feedbackResponse.thankYouPageInfo.fbUrl ||
                            feedbackResponse.thankYouPageInfo.yelpUrl))) ? (
                            <div className="text-center">
                              <h3 className="mb-4">
                                {" "}
                                {/* Please choose your favorite app below and leave us a review */}
                                {feedbackDetails?.business?.reviewText}
                              </h3>

                              <div className="favorite-app-btn-wrap mb-3">
                                <div>
                                  {feedbackResponse.thankYouPageInfo.gmbUrl &&
                                  feedbackResponse.thankYouPageInfo.gmbUrl
                                    .length ? (
                                    <a
                                      role="button"
                                      href={
                                        feedbackResponse.thankYouPageInfo.gmbUrl
                                      }
                                      className="btn social-btn"
                                    >
                                      <img
                                        src="https://sites.google.com/a/evoapa.com/evoapa/_/rsrc/1478794464468/samarbetspartner/google.png"
                                        style={{ height: 50 }}
                                        alt="Logo"
                                      />
                                    </a>
                                  ) : null}
                                  {feedbackResponse.thankYouPageInfo.fbUrl &&
                                  feedbackResponse.thankYouPageInfo.fbUrl
                                    .length ? (
                                    <a
                                      role="button"
                                      href={
                                        feedbackResponse.thankYouPageInfo.fbUrl
                                      }
                                      className="btn social-btn"
                                    >
                                      <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Facebook.svg/1280px-Facebook.svg.png"
                                        style={{ height: 50 }}
                                        alt="Logo"
                                      />
                                    </a>
                                  ) : null}
                                  {feedbackResponse.thankYouPageInfo.yelpUrl &&
                                  feedbackResponse.thankYouPageInfo.yelpUrl
                                    .length ? (
                                    <a
                                      role="button"
                                      href={
                                        feedbackResponse.thankYouPageInfo
                                          .yelpUrl
                                      }
                                      className="btn social-btn"
                                    >
                                      <img
                                        src="https://www.trzcacak.rs/file/max/2/21631_yelp-logo-png.jpg"
                                        style={{ height: 50 }}
                                        alt="Logo"
                                      />
                                    </a>
                                  ) : null}
                                </div>

                                {feedbackResponse.thankYouPageInfo.receiveFeedbackSites.map(
                                  (site, siteIndex) => (
                                    <a
                                      href={site.url}
                                      role="button"
                                      className="btn social-btn"
                                      key={siteIndex}
                                    >
                                      <img
                                        src={this._getLogo(site)}
                                        style={{ height: 50 }}
                                        alt="Logo"
                                      />
                                    </a>
                                  )
                                )}
                              </div>
                              {/* <!-- d-flex  --> */}
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                        {/* <!-- col-12  --> */}
                      </div>
                      {/* <!-- row  --> */}
                    </div>
                    {/* <!-- card-body --> */}
                  </div>
                </div>
              </div>
              {/* <!-- row  --> */}
            </div>
            {/* <!-- container --> */}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default NegativeFeedback;
