import React, { Component } from "react";
import Loader from "react-loader-spinner";

class FullScreenLoader extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div
          style={
            this.props.loaderWrapperStyle
              ? this.props.loaderWrapperStyle
              : {
                  width: "100%",
                  height: "100vh",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                }
          }
        >
          <div
            style={{
              width: 200,
              height: 150,
              textAlign: "center",
            }}
          >
            <Loader type="Oval" color="#33b377" />
            <p
              style={{
                fontFamily: "Hind",
                fontSize: 25,
                marginTop: 10,
                color: "#33b377",
                fontWeight: 500,
              }}
            >
              {this.props.loaderText ? this.props.loaderText : "Loading"}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FullScreenLoader;
