import React, { Component } from 'react';

const NoAccess = () => {
    return (  
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center" style={{ height: 'calc(100vh - 115px)'}}>
                <h2>No Access!</h2>
            </div>
        </React.Fragment>
    );
}
 
export default NoAccess;
