import React, { Component } from "react";

class NpsFeedbackRecorder extends Component {
  state = {
    selectedRating: 10,
    options: [{
      rating: 10,
      buttonText: '10 Very Likely'
    }, {
      rating: 9,
      buttonText: '9'
    }, {
      rating: 8,
      buttonText: '8'
    }, {
      rating: 7,
      buttonText: '7'
    }, {
      rating: 6,
      buttonText: '6'
    }, {
      rating: 5,
      buttonText: '5'
    }, {
      rating: 4,
      buttonText: '4'
    }, {
      rating: 3,
      buttonText: '3'
    }, {
      rating: 2,
      buttonText: '2'
    }, {
      rating: 1,
      buttonText: '1'
    }]
  };

  _updateRating = option => {
    this.setState({ selectedRating: option.rating });
  }

  _onSubmit = () => {
    this.props.onSubmit({
      type: 'nps',
      value: this.state.selectedRating
    })
  }

  render() {
    const { selectedRating, options } = this.state;
    return (
      <React.Fragment>
        <div className="app-body">
          <main className="main">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="text-center">
                            <h3 className="mb-4">
                              Would you recommend us to your friends?
                            </h3>

                            <div
                              className="recommend-chance-wrap"
                              data-toggle="buttons"
                            >
                              {options.map((option, optionIndex) => (
                                <button
                                  key={optionIndex}
                                  className={
                                    selectedRating === option.rating ? "btn active" : "btn"
                                  }
                                  onClick={() => this._updateRating(option)}
                                >
                                  { option.buttonText }
                                </button>
                              ))}
                            </div>

                            <button
                              type="button"
                              className="btn theme-btn btn-lg mt-5 mb-2"
                              onClick={() => this._onSubmit()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        {/* <!-- col-12  --> */}
                      </div>
                      {/* <!-- row  --> */}
                    </div>
                    {/* <!-- card-body --> */}
                  </div>
                </div>
              </div>
              {/* <!-- row  --> */}
            </div>
            {/* <!-- container --> */}
          </main>
        </div>
        {/* <!-- app-body --> */}
      </React.Fragment>
    );
  }
}

export default NpsFeedbackRecorder;
