import React, { Component } from "react";
import "../assets/styles/thumbs-up-down-style.css";

class ThumbsUpDownFeedbackRecorder extends Component {
  state = {
    currentSelection: "up",
  };

  _updateSelection = (newSelection) => {
    this.setState({ currentSelection: newSelection });
  };

  _onSubmit = () => {
    this.props.onSubmit({
      type: "thumbsupdown",
      value: this.state.currentSelection,
    });
  };

  render() {
    const { currentSelection } = this.state;
    return (
      <React.Fragment>
        ​
        <div className="app-body">
          <main className="main">
            ​
            <div className="container">
              <div className="row"></div>​
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h3 style={{ marginBottom: 50 }}>
                            {" "}
                            Would you recommend us?{" "}
                          </h3>
                          <div
                            className="btn-group btn-group-toggle mb-3 feedback-radio-btn"
                            data-toggle="buttons"
                          >
                            {/* <!-- Note: toggle states using "active" class --> */}
                            <button
                              className={
                                currentSelection === "up"
                                  ? "btn theme-btn"
                                  : "btn outline-theme-btn"
                              }
                              onClick={() => this._updateSelection("up")}
                            >
                              <i
                                style={{
                                  color:
                                    currentSelection === "up"
                                      ? "white"
                                      : "#33b377",
                                }}
                                className="fa fa-thumbs-o-up"
                              ></i>
                            </button>
                            <button
                              className={
                                currentSelection === "down"
                                  ? "btn  btn-outline-danger-active"
                                  : "btn btn-outline-danger"
                              }
                              onClick={() => this._updateSelection("down")}
                            >
                              <i className="fa fa-thumbs-o-down"></i>
                            </button>
                          </div>
                          <button
                            type="button"
                            className="btn theme-btn btn-lg mb-4"
                            style={{ marginTop: 30 }}
                            onClick={() => this._onSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                        {/* <!-- col-12  --> */}
                      </div>
                      {/* <!-- row  --> */}
                    </div>
                    {/* <!-- card-body --> */}
                  </div>
                </div>
              </div>
              {/* <!-- row  --> */}
            </div>
            {/* <!-- container --> */}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default ThumbsUpDownFeedbackRecorder;
