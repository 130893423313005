import React, { Component } from "react";
import Loader from "react-loader-spinner";

class OnScreenLoader extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div
          style={
            this.props.loaderWrapperStyle
              ? this.props.loaderWrapperStyle
              : {
                  width: "100%",
                  height: "85vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }
          }
        >
          <div
            style={{
              width: 200,
              height: 150,
              textAlign: "center"
            }}
          >
            <Loader type="Oval" color="#2E817F"/>
            <p
              style={{
                fontFamily: "sans-serif",
                fontSize: 17,
                marginTop: 10,
                color: "#2E817F",
                fontWeight: 600
              }}
            >
              {this.props.loaderText ? this.props.loaderText : "Loading"}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OnScreenLoader;
