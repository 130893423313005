import React, { Component } from "react";
import PositiveFeedback from "./positive-feedback";
import NegativeFeedback from "./negative-feedback";

class ThankYou extends Component {
  state = {
    currentType: "positive"
  };

  componentDidMount() {
    const { feedbackResponse, feedbackDetails } = this.props;
    console.log('object :>> ', feedbackResponse, feedbackDetails);
    if (feedbackResponse) {
      if (feedbackResponse.isPositive) {
        this.setState({ currentType: "positive" }, () => {
            if (feedbackResponse.thankYouPageInfo && feedbackResponse.thankYouPageInfo.headerColor && feedbackResponse.thankYouPageInfo.headerColor.length) {
                this.props.changeHeaderColor(feedbackResponse.thankYouPageInfo.headerColor);
            }
        });
      } else {
        this.setState({ currentType: "negative" }, () => {
            if (feedbackResponse.thankYouPageInfo && feedbackResponse.thankYouPageInfo.headerColor && feedbackResponse.thankYouPageInfo.headerColor.length) {
                this.props.changeHeaderColor(feedbackResponse.thankYouPageInfo.headerColor);
            }
        });
      }
    } else {
        this.setState({ currentType: "positive" });
    }
  }

  render() {
    const { feedbackResponse, feedbackDetails } = this.props;
    const { currentType } = this.state;
    return (
      <React.Fragment>
        {currentType === "positive" ? (
          <PositiveFeedback
            feedbackResponse={feedbackResponse || feedbackDetails}
            feedbackDetails={feedbackDetails}
          />
        ) : (
          <NegativeFeedback
            feedbackResponse={feedbackResponse || feedbackDetails}
            feedbackDetails={feedbackDetails}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ThankYou;
