import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/styles/main.css";
import "./assets/styles/custom.css";
import "./assets/styles/react.css";
import "./App.css";
import MainPage from "./pages/MainPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  ToastsStore,
  ToastsContainerPosition,
  ToastsContainer,
} from "react-toasts";
import "./assets/styles/_index.scss";

function App() {
  return (
    <div className="App">
      <Router>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.BOTTOM_RIGHT}
        />
        <Switch>
          <Route exact path="*" component={MainPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
