/*  Production Values  */

// export const BASE_URL = "https://api.repustream.com/api/v1";             //.. Production

/*  Dev values  */

export const BASE_URL =
"https://api.syndly.com/api/v1";
//  "https://api.repugains.com/api/v1"; //.. Dev
// "http://178.128.127.115:5000/api/v1";
// export const BASE_URL = "https://tradehub.logic-square.com/api/v1"; //.. Dev

/*  Common Values  */
export const feedbackReviewSites = {
  Automotive: [
    {
      name: "Cars.com",
      indexName: "cars.com",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576502829/repustream/Carscom_Logo.jpg",
    },
    {
      name: "Dealer Rater",
      indexName: "dealerrater",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503011/repustream/qdntias5gyhegqpbaun7.png",
    },
    {
      name: "Snap 21",
      indexName: "snap21",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503111/repustream/ofwuu9fhvkusau9omexr.png",
    },
    {
      name: "Autotrader",
      indexName: "autotrader",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503154/repustream/autotrader-logo-vector.png",
    },
    {
      name: "Edmunds",
      indexName: "edmunds",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503224/repustream/Edmunds_Logo.png",
    },
    {
      name: "Car Gurus",
      indexName: "cargurus",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503269/repustream/cargurus_owler_20190704_123815_original.png",
    },
    {
      name: "KBB",
      indexName: "kbb",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503385/repustream/kelley-blue-book_owler_20170325_150525_original.png",
    },
    {
      name: "CARFAX",
      indexName: "carfax",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503435/repustream/CARFAX-LOGO.jpg",
    },
    {
      name: "Auto.com",
      indexName: "autodotcom",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503481/repustream/cu94ub0y4qhhqvidu9t0.png",
    },
    {
      name: "TrueCar.com",
      indexName: "truecardotcom",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503538/repustream/rntgtcgbx38l3jkrl7l6.png",
    },
    {
      name: "Autobytel",
      indexName: "autobytel",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503656/repustream/autobytel-sq.png",
    },
    {
      name: "The Car Connection",
      indexName: "thecarconnection",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503704/repustream/featured-in-logos_The-car-connection.png",
    },
    {
      name: "Autolist",
      indexName: "autolist",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576503758/repustream/publisher_autolist.png",
    },
  ],
  Financial: [
    {
      name: "Credit Karma",
      indexName: "creditkarma",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576504124/repustream/c87jffolo8q6etczbujb.png",
    },
    {
      name: "Wallethub",
      indexName: "wallethub",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576504216/repustream/Wallethub-logo.jpg",
    },
  ],
  "Home Improvement": [
    {
      name: "Home Advisor",
      indexName: "homeadvisor",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576504280/repustream/778e4694-7769-4ceb-bfca-a426ce58faee_l.png",
    },
    {
      name: "Houzz",
      indexName: "houzz",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576504356/repustream/houzz_logo.png",
    },
    {
      name: "Angies List",
      indexName: "angieslist",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576504483/repustream/gqfm8celrapfiu1srmra.png",
    },
    {
      name: "Thumbtack",
      indexName: "thumbtack",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576504528/repustream/thumbtack-logo-8D06EC40F6-seeklogo.com.png",
    },
    {
      name: "porch",
      indexName: "porch",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576506920/repustream/images.png",
    },
  ],
  Hospitality: [
    {
      name: "Booking.com",
      indexName: "bookingdotcom",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507029/repustream/1280px-Booking.com_logo.svg.png",
    },
    {
      name: "Expedia",
      indexName: "expedia",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507070/repustream/expedia.svg",
    },
    {
      name: "Orbitz",
      indexName: "orbitz",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507119/repustream/1280px-Orbitz_logo.svg.png",
    },
    {
      name: "Travelocity",
      indexName: "travelocity",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507163/repustream/Travelocity_Logo.png",
    },
    {
      name: "Trip Advisor",
      indexName: "tripadvisor",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507233/repustream/TA_logo_primary.png",
    },
    {
      name: "Airbnb",
      indexName: "airbnb",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507285/repustream/5600ddbbc680a4a100e62077d90763eb.jpg",
    },
    {
      name: "Hotels.com",
      indexName: "hotelsdotcom",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507342/repustream/hotels-com-vector-logo.png",
    },
    {
      name: "Kayak",
      indexName: "kayak",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507385/repustream/kayak-vector-logo.png",
    },
    {
      name: "Priceline.com",
      indexName: "pricelinedotcom",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507438/repustream/priceline-com-vector-logo.png",
    },
    {
      name: "Trivago",
      indexName: "trivago",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507515/repustream/images.png",
    },
    {
      name: "Oyster",
      indexName: "oyster",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507580/repustream/images.png",
    },
    {
      name: "Agoda",
      indexName: "agoda",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507668/repustream/w0nfrgunftln961tw0zl.png",
    },
    {
      name: "Hotels Combined",
      indexName: "hotelscombined",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507717/repustream/hotelscombined_owler_20171221_185309_original.jpg",
    },
  ],
  "Senior Living": [
    {
      name: "Caring",
      indexName: "caring",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576507844/repustream/caring-logo-.jpg",
    },
    {
      name: "Our Parents",
      indexName: "ourparents",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576508059/repustream/ms9zmumksa9hafyiriwv.png",
    },
    {
      name: "Senior Advisor",
      indexName: "senioradvisor",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576509965/repustream/yz2y1mptk4kyhuuikerz.png",
    },
    {
      name: "Senior Home",
      indexName: "seniorhome",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510032/repustream/l6dxktfzws3rj58y1bcg.jpg",
    },
  ],
  Legal: [
    {
      name: "Avvo",
      indexName: "avvo",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510135/repustream/hlljrv3iuhymiwq8ev5a.png",
    },
    {
      name: "Find Law",
      indexName: "findlaw",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510208/repustream/FindLaw_LOGO.jpg",
    },
    {
      name: "Justia",
      indexName: "justia",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510267/repustream/justia_20lawyers.jpg",
    },
    {
      name: "Lawyers.com",
      indexName: "lawyersdotcom",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510367/repustream/LDC_No_Tagline_Logo_K_3005C_Mech_Logo.jpg",
    },
    {
      name: "Superlawyers.com",
      indexName: "superlawyers.com",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510532/repustream/super-lawyer.png",
    },
  ],
  Medical: [
    {
      name: "Rate MDs",
      indexName: "ratemds",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510630/repustream/551346.jpg",
    },
    {
      name: "Real Patient Ratings",
      indexName: "realpatientratings",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510694/repustream/RealPatientRatings-1.jpg",
    },
    {
      name: "U Compare Healthcare",
      indexName: "ucomparehealthcare",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510749/repustream/Ucompare.png",
    },
    {
      name: "Vitals",
      indexName: "vitals",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510831/repustream/logo-vitals-purple.png",
    },
    {
      name: "Zoc Doc",
      indexName: "zocdoc",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510873/repustream/zocdoc_logo.png",
    },
    {
      name: "Realself",
      indexName: "realself",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576510960/repustream/realself-press-primary-logo.png",
    },
    {
      name: "Health Grades",
      indexName: "healthgrades",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511093/repustream/healthgrades-logo-2018.png",
    },
    {
      name: "Web MD Physical Directory",
      indexName: "webmdphysicaldirectory",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511013/repustream/sh6bppd1lps5xgszjhzj.png",
    },
  ],
  "Real Estate": [
    {
      name: "Rent",
      indexName: "rent",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511140/repustream/rent-logo.png",
    },
    {
      name: "Renters Voice",
      indexName: "rentersvoice",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511183/repustream/TRV_horizontal.png",
    },
    {
      name: "Zillow",
      indexName: "zillow",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511235/repustream/Zillow_Wordmark_Blue_RGB_lrg.png",
    },
    {
      name: "Trulia",
      indexName: "trulia",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511280/repustream/322861LOGO.jpg",
    },
    {
      name: "Realtor.com",
      indexName: "realtor.com",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511323/repustream/realtor_dot_com_logo_detail.png",
    },
    {
      name: "Redfin",
      indexName: "redfin",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511370/repustream/5001-4.jpg",
    },
    {
      name: "Homes.com",
      indexName: "homes.com",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511403/repustream/Homes.com-logo.jpg",
    },
    {
      name: "Movoto",
      indexName: "movoto",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511439/repustream/SF26622LOGO.jpg",
    },
  ],
  Resturants: [
    {
      name: "MenuPages",
      indexName: "menupages",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511542/repustream/menupages_logo_s.png",
    },
    {
      name: "Zomato",
      indexName: "zomato",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511622/repustream/zomato_newlogo.png",
    },
    {
      name: "Open Table",
      indexName: "opentable",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511827/repustream/OTLogo_singlecolor-2019-1.jpg",
    },
    {
      name: "All Menus",
      indexName: "allmenus",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511864/repustream/Allmenus-Logo.jpg",
    },
    {
      name: "Grub Hub",
      indexName: "grubhub",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511903/repustream/1280px-GrubHub_Logo_2016.svg.png",
    },
    {
      name: "Menuism",
      indexName: "menuism",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576511945/repustream/logowhite.jpg",
    },
    {
      name: "Menupix",
      indexName: "menupix",
      logoUrl:
        "https://res.cloudinary.com/dqafmb032/image/upload/v1576512000/repustream/uc6bghewuqytxshnjpl9.png",
    },
  ],
  Directory: [
    {
      name: "Angies List",
      indexName: "angieslist",
      logoUrl:
        "https://www.tfaforms.com/forms/get_image/190294/694f69be187fdf08be1690185b8244b9-Angieslist-logo.png",
    },
    {
      name: "BBB",
      indexName: "bbb",
      logoUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5hmlKWtWZwLwbbL-xquBVJNAVTbMBO2iim8kfvW1NubnHbTHh",
    },
    {
      name: "City Search",
      indexName: "citysearch",
      logoUrl:
        " https://angletonvetclinic.com/storage/app/media/img_transfer/city_search.png",
    },
    {
      name: "Dex Knows",
      indexName: "dexknows",
      logoUrl:
        "https://ecowaternebraska.com/i/content/review-instructions/logo/dexknows-300dpi.png",
    },
    {
      name: "Experience Pros",
      indexName: "experiencepros",
      logoUrl:
        " https://www.chrisruisi.com/blog/wp-content/uploads/ExperiencePros-logo-large2015.png",
    },
    {
      name: "Insider Pages",
      indexName: "insiderpages",
      logoUrl:
        "https://www.equitynet.com/m/profile/100408N9bt/insider-pages-logo.jpg?v=t4j",
    },
    {
      name: "Manta",
      indexName: "manta",
      logoUrl:
        "https://www.sccpre.cat/mypng/detail/400-4000184_manta-logo-manta-small-business-logo.png",
    },
    {
      name: "Merchant Circle",
      indexName: "merchantcircle",
      logoUrl: "https://logodix.com/logo/2091583.jpg",
    },
    {
      name: "Reviews",
      indexName: "reviews",
      logoUrl:
        "https://cdn.tradecentregroup.io/image/upload/v1550240851/reviews-1.png",
    },
    {
      name: "Super Pages",
      indexName: "superpages",
      logoUrl:
        "https://cdn.shopify.com/s/files/1/0070/9043/8255/products/logos-superpages_600x600.png?v=1540515691",
    },
    {
      name: "Sure Critic",
      indexName: "surecritic",
      logoUrl:
        "https://dd5cthgnln9mv.cloudfront.net/assets/surecritic_logo_no_tag-e6d40f70bc27ce81ebf4238985c64634397808d193fa579b082379010dfccf3e.svg ",
    },
    {
      name: "Yahoo Local",
      indexName: "yahoo",
      logoUrl:
        "https://www.gocanvas.com/content/images/image-uploads/Help_Topics/Logos/yahoo_local.png",
    },
    {
      name: "Yellow Book",
      indexName: "yellowbook",
      logoUrl:
        "http://cdn2.hubspot.net/hub/23819/file-13477167-jpg/images/yellowbook_logo.jpg",
    },
    {
      name: "Yellow Pages",
      indexName: "yellowpages",
      logoUrl: "https://logo-logos.com/wp-content/uploads/2016/11/YP_logo.png",
    },
  ],
  Social: [
    {
      name: "Foursquare",
      indexName: "foursquare",
      logoUrl:
        "https://149351874.v2.pressablecdn.com/wp-content/uploads/2018/12/Foursquare-Logo-new.png",
    },
    {
      name: "LinkedIn",
      indexName: "linkedIn",
      logoUrl:
        "https://static.adweek.com/adweek.com-prod/wp-content/uploads/2019/06/linkedin-branding-CONTENT-2019.jpg",
    },
    {
      name: "Next Door",
      indexName: "nextdoor",
      logoUrl: "http://karihaas.com/files/2019/06/Nextdoor-logo.png",
    },
    {
      name: "Twitter",
      indexName: "twitter",
      logoUrl:
        "https://www.brainpulse.com/wp-content/uploads/2015/04/twitter_logo.jpg",
    },
  ],
};
