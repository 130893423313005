import {
  makeGetRequest,
  makePostRequest,
  uploadFile,
  makePutRequest,
  makeDeleteRequest
} from "../http-connectors";
import { BASE_URL } from "../config/index";

export const getFeedbackDetails = token => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/feedback/details", {
      token
    })
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const submitFeedback = (token, feedback) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/feedback/submit", {
      token, feedback
    })
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};


export const submitNegativeReview = (token, review) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/feedback/review", {
      token, review
    })
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};