import React, { Component } from 'react';

class YesNoFeedbackRecorder extends Component {
    state = {  
      currentSelection: 'yes'
    }

    _updateSelection = type => {
      this.setState({currentSelection: type}, () => {
        this.props.onSubmit({
          type: 'yesno',
          value: this.state.currentSelection
        })
      });
    }

    render() { 
      const { currentSelection } = this.state;
        return (
          <React.Fragment>
            <div className="app-body">
              <main className="main">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-center">
                                <h3 className="mb-4"> Would you recommend us? </h3>

                                <button
                                  type="button"
                                  className="btn theme-btn mt-5 mb-3 d-block mx-auto"
                                  onClick={() => this._updateSelection('yes')}
                                >
                                  Yes
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-outline-secondary cancel-btn mt-3 mb-2"
                                  onClick={() => this._updateSelection('no')}
                                >
                                  No Thanks
                                </button>
                              </div>
                            </div>
                            {/* <!-- col-12  --> */}
                          </div>
                          {/* <!-- row  --> */}
                        </div>
                        {/* <!-- card-body --> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- row  --> */}
                </div>
                {/* <!-- container --> */}
              </main>
            </div>
          </React.Fragment>
        );
    }
}
 
export default YesNoFeedbackRecorder;