import React, { Component } from "react";
import FeedbackRecorder from "../components/feedback-recorder";
import ThankYou from "../components/thank-you";
import NoAccess from "../components/no-access";
import FullScreenLoader from "../components/full-screen-loader";
import { sleepTime, deepClone } from "../helper-methods";
import { getFeedbackDetails, submitFeedback } from "../http-calls";

class MainPage extends Component {
  state = {
    currentMode: "record", //  'record' / 'thnakyou' / 'noaccess
    isLoaded: false,
    feedbackDetails: null,
    feedbackResponse: null,
    headerColor: "rgb(247, 247, 247)",
  };

  async componentDidMount() {
    // First check if any valid customer feedback id is provided or not
    try {
      const feedbackToken = await this._validateFeedbackToken();
      const feedbackDetails = await this._getFeedbackDetails(feedbackToken);
      this.setState({ feedbackDetails }, () => {
        this._setCurrentMode();
      });
    } catch (err) {
      await sleepTime(1000);
      this.setState({ currentMode: "noaccess", isLoaded: true });
    }
  }

  _setCurrentMode = async () => {
    // this.setState({ currentMode: 'record', isLoaded: true })
    const { feedbackDetails } = deepClone(this.state);
    if (feedbackDetails.hasSubmittedFeedback) {
      this.setState({ currentMode: "thankyou", isLoaded: true });
    } else {
      const params = this._hasProperSubmissionValues();
      if (params && params.length) {
        try {
          const response = await this._submitFeedback(params);
          this.setState({feedbackDetails:{...feedbackDetails,
            thankYouPageInfo:response.thankYouPageInfo,
            hasSubmittedFeedback:true}})
          this._showThankYouPage(response);
        } catch (error) {
          this.setState({ currentMode: "record", isLoaded: true });
        }
      } else {
        this.setState({ currentMode: "record", isLoaded: true });
      }
    }
  };

  _submitFeedback = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const feedback = {};
        feedback[params[0].value] = params[1].value;
        const response = await submitFeedback(
          window.location.pathname.replace("/", ""),
          feedback
        );
        resolve(response);
      } catch (error) {
        console.log("error :", error);
        reject(error);
      }
    });
  };

  _hasProperSubmissionValues = () => {
    const { search: queryParamsString } = window.location;
    let params = [];
    if (queryParamsString.length) {
      const queryParams = queryParamsString.replace("?", "").split("&");
      params = queryParams.map((qp) => {
        return {
          key: qp.split("=")[0],
          value: qp.split("=")[1],
        };
      });
    }
    return params;
  };

  _getFeedbackDetails = (feedbackToken) => {
    return new Promise(async (resolve, reject) => {
      try {
        const feedbackDetails = await getFeedbackDetails(feedbackToken);
        if (feedbackDetails && !feedbackDetails.error) {
          resolve(feedbackDetails);
        } else {
          reject();
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  _validateFeedbackToken = () => {
    return new Promise(async (resolve, reject) => {
      const urlPayload = window.location.pathname;
      if (urlPayload && urlPayload.length && urlPayload[0] === "/") {
        const feedbackTokenParts = urlPayload.split("/");
        if (feedbackTokenParts && feedbackTokenParts.length === 2) {
          const feedbackToken = feedbackTokenParts[1];
          resolve(feedbackToken);
        }
      }
      // Valid token not present
      reject("Not a valid token");
    });
  };

  _showThankYouPage = (feedbackResponse) => {
    this.setState({ feedbackResponse }, () => {
      this.setState({ currentMode: "thankyou", isLoaded: true });
    });
  };

  _showNoAccessPage = () => {
    this.setState({ currentMode: "noaccess" });
  };

  _changeHeaderColor = (colorCode) => {
    this.setState({
      headerColor: colorCode,
    });
  };

  render() {
    const {
      currentMode,
      isLoaded,
      feedbackDetails,
      feedbackResponse,
      headerColor,
    } = this.state;
    return (
      <React.Fragment>
        {isLoaded ? (
          <div className="app header-fixed customer-page" id="profile-page">
            <div className="custom-header" style={{backgroundColor:feedbackDetails?.hasSubmittedFeedback?feedbackDetails?.thankYouPageInfo?.headerColor:'#ffffff'}}>
              <h3 className="mb-0">
                {feedbackDetails &&
                feedbackDetails.business &&
                feedbackDetails.business.name
                  ? feedbackDetails.business.name
                  : "Syndly"}
              </h3>
              <p className="my-2">
                {feedbackDetails &&
                feedbackDetails.business &&
                feedbackDetails.business.address
                  ? feedbackDetails.business.address
                  : ""}
              </p>
            </div>
            {currentMode === "record" ? (
              <FeedbackRecorder
                feedbackDetails={feedbackDetails}
                onRecorded={(feedbackResponse) =>
                  this._showThankYouPage(feedbackResponse)
                }
                showNoAccessPage={() => this._showNoAccessPage()}
              />
            ) : currentMode === "thankyou" ? (
              <ThankYou
                feedbackDetails={feedbackDetails}
                feedbackResponse={feedbackResponse || feedbackDetails}
                showNoAccessPage={() => this._showNoAccessPage()}
                changeHeaderColor={(colorCode) =>
                  this._changeHeaderColor(colorCode)
                }
              />
            ) : (
              <NoAccess />
            )}

            <footer className="app-footer">
              <div style={{ textAlign: "center", width: "100%" }}>
                Powered By: Syndly
              </div>
            </footer>
          </div>
        ) : (
          <FullScreenLoader />
        )}
      </React.Fragment>
    );
  }
}

export default MainPage;
