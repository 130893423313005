import React, { Component } from "react";
import StarRatings from 'react-star-ratings';


class StarFeedbackRecorder extends Component {
  state = {
    rating: 5
  };

  changeRating = ( newRating, name ) =>  {
    this.setState({
      rating: newRating
    });
  }

  _onSubmit = () => {
    this.props.onSubmit({
      type: 'star',
      value: this.state.rating
    })
  }


  render() {
    return (
      <React.Fragment>
        <div className="app-body">
          <main className="main">
            <div className="container" style={{ paddingTop: 60 }}>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="text-center">
                            <h3 style={{marginBottom: 32}}> Please rate us! </h3>

                            {/* <!-- rating starts is to be included inside the below div --> */}
                            <div>
                              <StarRatings
                                rating={this.state.rating}
                                starRatedColor="#FED82F"
                                changeRating={this.changeRating}
                                starHoverColor="#FED82F"
                                numberOfStars={5}
                                name="rating"
                              />
                            </div>

                            <button
                              type="button"
                              className="btn theme-btn btn-lg mb-2"
                              style={{marginTop: 32}}
                              onClick={ () => this._onSubmit() }
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        {/* <!-- col-12  --> */}
                      </div>
                      {/* <!-- row  --> */}
                    </div>
                    {/* <!-- card-body --> */}
                  </div>
                </div>
              </div>
              {/* <!-- row  --> */}
            </div>
            {/* <!-- container --> */}
          </main>
        </div>
        {/* <!-- app-body --> */}
      </React.Fragment>
    );
  }
}

export default StarFeedbackRecorder;
