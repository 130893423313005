import React, { Component } from "react";
import { feedbackReviewSites } from "../config";
import { ToastsStore } from "react-toasts";
import { submitNegativeReview } from "../http-calls/index";

class PositiveFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewText: "",
      isWrittenReviewSubmitted: false,
      reviewSites: this._convertFeedbackSitesToSingleArray(),
    };
  }

  _convertFeedbackSitesToSingleArray = () => {
    const feedbackReviewSitesArray = [];
    for (let categoryName in feedbackReviewSites) {
      const siteCount = feedbackReviewSites[categoryName].length;
      for (let siteIndex = 0; siteIndex < siteCount; siteIndex++) {
        feedbackReviewSitesArray.push(
          feedbackReviewSites[categoryName][siteIndex]
        );
      }
    }
    return feedbackReviewSitesArray;
  };

  componentDidMount() {}

  _getLogo = (site) => {
    const { reviewSites } = this.state;
    const filteredSite = reviewSites.find((s) => s.indexName === site.name);
    if (filteredSite) {
      return filteredSite.logoUrl;
    } else {
      switch (site.name) {
        case "gmbUrl": {
          return "https://sites.google.com/a/evoapa.com/evoapa/_/rsrc/1478794464468/samarbetspartner/google.png";
        }
        case "fbUrl": {
          return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Facebook.svg/1280px-Facebook.svg.png";
        }
        case "yelpUrl": {
          return "https://www.trzcacak.rs/file/max/2/21631_yelp-logo-png.jpg";
        }
        default: {
          return site.logoUrl;
        }
      }
    }
  };

  _getThankYouPageContents = (feedbackDetails, feedbackResponse) => {
    const contents = {
      hasSubmitted: false,
    };
    let thankYouPageInfo = {};
    if (feedbackResponse && Object.keys(feedbackResponse).length) {
      if (
        feedbackResponse.thankYouPageInfo &&
        Object.keys(feedbackResponse.thankYouPageInfo)
      ) {
        thankYouPageInfo = feedbackResponse.thankYouPageInfo;
      }
    } else {
      // Response not available
      // Already submitted
      contents.hasSubmitted = true;
      if (
        feedbackDetails.thankYouPageInfo &&
        Object.keys(feedbackDetails.thankYouPageInfo).length
      ) {
        thankYouPageInfo = feedbackDetails.thankYouPageInfo;
      }
    }
    if (thankYouPageInfo.html && thankYouPageInfo.html.length) {
      contents["html"] = thankYouPageInfo.html;
    }
    if (thankYouPageInfo.imgUrl && thankYouPageInfo.imgUrl.length) {
      contents["imgUrl"] = thankYouPageInfo.imgUrl;
    }
    if (thankYouPageInfo.videoUrl && thankYouPageInfo.videoUrl.length) {
      contents["videoUrl"] = thankYouPageInfo.videoUrl;
    }
    contents["feedbackSitesCount"] = 0;
    if (thankYouPageInfo.gmbUrl && thankYouPageInfo.gmbUrl.length) {
      contents["gmbUrl"] = thankYouPageInfo.gmbUrl;
      contents["feedbackSitesCount"]++;
    }
    if (thankYouPageInfo.fbUrl && thankYouPageInfo.fbUrl.length) {
      contents["fbUrl"] = thankYouPageInfo.fbUrl;
      contents["feedbackSitesCount"]++;
    }
    if (thankYouPageInfo.yelpUrl && thankYouPageInfo.yelpUrl.length) {
      contents["yelpUrl"] = thankYouPageInfo.yelpUrl;
      contents["feedbackSitesCount"]++;
    }
    // Process other receiveFeedbackSites
    if (
      thankYouPageInfo.receiveFeedbackSites &&
      thankYouPageInfo.receiveFeedbackSites.length
    ) {
      contents["receiveFeedbackSites"] = thankYouPageInfo.receiveFeedbackSites;
      contents["feedbackSitesCount"] +=
        thankYouPageInfo.receiveFeedbackSites.length;
    }
    return contents;
  };

  _onUpdate = (reviewText) => {
    this.setState({ reviewText });
  };

  _submitReview = () => {
    submitNegativeReview(
      this.props.feedbackDetails.token,
      this.state.reviewText
    );
    this.setState({ isWrittenReviewSubmitted: true });
    ToastsStore.success("Thank you for your feedback", 5000);
  };

  _rawHtml = (htmlreceived) => {
    var rawMarkup = htmlreceived;
    console.log("raw markup received:", rawMarkup);
    return { __html: rawMarkup };
  };

  render() {
    const { feedbackDetails, feedbackResponse } = this.props;
    const thankYouPageContents = this._getThankYouPageContents(
      feedbackDetails,
      feedbackResponse
    );
    const { reviewText, isWrittenReviewSubmitted, reviewSites } = this.state;
    let askForWrittenReview = false;
    if (
      feedbackResponse &&
      feedbackResponse.hasOwnProperty(
        "askForWrittenReviewForPositiveFeedback"
      ) &&
      !isWrittenReviewSubmitted
    ) {
      askForWrittenReview =
        feedbackResponse.askForWrittenReviewForPositiveFeedback;
    }
    return (
      <React.Fragment>
        <div className="app-body" style={{ marginTop: "20px" }}>
          <main className="main">
            <div className="container">
              <div className="row"></div>
              <div className="row">
                <div className="col-12">
                  <div className="card mt-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div style={{ marginBottom: 30 }}>
                            {thankYouPageContents.html &&
                            thankYouPageContents.html.length ? (
                              <div
                                className="text-center"
                                style={{
                                  marginBottom:
                                    (!feedbackResponse &&
                                      feedbackDetails &&
                                      feedbackDetails.hasSubmittedFeedback &&
                                      !feedbackDetails.isPositive) ||
                                    (feedbackResponse &&
                                      Object.keys(feedbackResponse).length &&
                                      !feedbackResponse.isPositive)
                                      ? 100
                                      : 0,
                                }}
                              >
                                {/* <p>
                                    {thankYouPageContents.html}
                                  </p> */}
                                <p>
                                  <span
                                    dangerouslySetInnerHTML={this._rawHtml(
                                      thankYouPageContents.html
                                    )}
                                  />
                                </p>
                              </div>
                            ) : null}
                            {thankYouPageContents.imgUrl &&
                            thankYouPageContents.imgUrl.length &&
                            thankYouPageContents.imgUrl !== "NA" ? (
                              <div
                                className="text-center"
                                style={{ marginBottom: 25 }}
                              >
                                <img
                                  style={{ width: "80%" }}
                                  src={thankYouPageContents.imgUrl}
                                  alt=""
                                />
                              </div>
                            ) : null}
                            {thankYouPageContents.videoUrl &&
                            thankYouPageContents.videoUrl.length &&
                            thankYouPageContents.videoUrl !== "NA" ? (
                              <div
                                className="text-center"
                                style={{ marginBottom: 25 }}
                              >
                                <video width="80%" height="240" controls>
                                  <source
                                    src={thankYouPageContents.videoUrl}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            ) : null}
                          </div>
                          {askForWrittenReview ? (
                            <div
                              className="text-center"
                              style={{ marginBottom: 50 }}
                            >
                              <h3 className="mb-4">
                                {" "}
                                Would love to hear from you
                              </h3>
                              <div className="form-group mt-4 feedback-textarea">
                                <textarea
                                  className="form-control"
                                  id=""
                                  rows="5"
                                  value={reviewText}
                                  placeholder="Comments (please be as specific as possible)"
                                  onChange={(e) =>
                                    this._onUpdate(e.target.value)
                                  }
                                ></textarea>
                              </div>
                              <button
                                type="button"
                                className="btn theme-btn btn-lg mt-2 mb-4"
                                style={
                                  !reviewText.length
                                    ? {
                                        background: "grey",
                                        borderColor: "grey",
                                      }
                                    : null
                                }
                                disabled={reviewText.length ? null : true}
                                onClick={() => this._submitReview()}
                              >
                                Submit
                              </button>
                            </div>
                          ) : null}
                          {thankYouPageContents.feedbackSitesCount > 0 ? (
                            <div className="text-center">
                              {/* Please choose your favorite app below and leave us a review */}
                              <h3 className="mb-4">
                                {feedbackDetails?.business?.reviewText}
                              </h3>

                              <div className="favorite-app-btn-wrap mb-3">
                                <div>
                                  {thankYouPageContents.gmbUrl &&
                                  thankYouPageContents.gmbUrl.length ? (
                                    <a
                                      role="button"
                                      href={thankYouPageContents.gmbUrl}
                                      className="btn social-btn"
                                    >
                                      <img
                                        src="https://sites.google.com/a/evoapa.com/evoapa/_/rsrc/1478794464468/samarbetspartner/google.png"
                                        alt=""
                                      />
                                    </a>
                                  ) : null}
                                  {thankYouPageContents.fbUrl &&
                                  thankYouPageContents.fbUrl.length ? (
                                    <a
                                      role="button"
                                      href={thankYouPageContents.fbUrl}
                                      className="btn social-btn"
                                    >
                                      <img
                                        src="https://res.cloudinary.com/www-logic-square-com/image/upload/v1706081917/general/facebook-logo-png-38355.png"
                                        alt="Logo"
                                      />
                                    </a>
                                  ) : null}
                                  {thankYouPageContents.yelpUrl &&
                                  thankYouPageContents.yelpUrl.length ? (
                                    <a
                                      role="button"
                                      href={thankYouPageContents.yelpUrl}
                                      className="btn social-btn"
                                    >
                                      <img
                                        src="https://www.trzcacak.rs/file/max/2/21631_yelp-logo-png.jpg"
                                        alt="Logo"
                                      />
                                    </a>
                                  ) : null}
                                </div>

                                {thankYouPageContents.receiveFeedbackSites.map(
                                  (site, siteIndex) => (
                                    <a
                                      href={site.url}
                                      role="button"
                                      className="btn social-btn"
                                      key={siteIndex}
                                    >
                                      <img
                                        src={this._getLogo(site)}
                                        alt="Logo"
                                      />
                                    </a>
                                  )
                                )}
                              </div>
                              {/* <!-- d-flex  --> */}
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                      {/* <!-- row  --> */}
                    </div>
                    {/* <!-- card-body --> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- container --> */}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default PositiveFeedback;
